import baseService from './baseService'

export const downloadFileService = {
  downloadReport,
  downloadGroupReport,
  downloadExcel,
  downloadSavedExcel,
  downloadResource
}

function downloadReport (data) {
  return baseService.fileDownloadPostRequest('data/pdf/report', data)
}
function downloadGroupReport (data) {
  return baseService.fileDownloadPostRequest('data/groupReportDownload', data)
}
function downloadExcel (data) {
  return baseService.fileDownloadPostRequest('data/export/excel', data)
}
function downloadSavedExcel (data) {
  return baseService.fileDownloadPostRequest('data/export/downloadExcel', data)
}
function downloadResource (data) {
  return baseService.fileDownloadPostRequest('data/file/learningResources', data)
}
