<template>
  <div>
    <v-app style="background: #ecf0f5">
      <InvalidSession v-if="invalid"></InvalidSession>
      <div v-if="!invalid">
        <SideBar v-if="isUserLegit"></SideBar>
        <Header></Header>
        <v-content>
          <Home></Home>
          <Footer></Footer>
        </v-content>
      </div>
    </v-app>
  </div>
</template>

<script>
import SideBar from '../components/SideBar'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Home from '../components/Home'
import InvalidSession from '../components/InvalidSession'
import { EventBus } from '../_helpers'

export default {
  name: 'App',
  components: {
    InvalidSession,
    Home,
    SideBar,
    Header,
    Footer
  },
  data () {
    return {
      drawer: true,
      mini: true,
      invalid: false
    }
  },
  computed: {
    isUserLegit () {
      return this.$store.state.home.authenticated && this.$store.state.home.user.dpaAccepted
    }
  },
  methods: {
    invalidatePage: function () {
      this.invalid = true
    }
  },
  created () {
    EventBus.$on('page_invalid', this.invalidatePage)
  }
}
</script>

<style>
  html,
  body {
    font-weight: 500;
    font-size: 16px;
  }

  .v-breadcrumbs li {
    font-size: 12px !important;
  }
</style>
