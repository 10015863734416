export const en = {
  msg:
    { // HomePage
      'registered_employees_text': 'Total Registered Test-Takers',
      'finished_employees_text': 'Total Reports Generated',
      'total_assessment': 'Total Assessment(s)',
      'yearDropDownLabel': 'Select Year',
      'assessmentDropDownLabelHome': 'Select Assessment',
      // Results Page
      'sessionDropdownLabel': 'Session',
      'assessmentDropdownLabel': 'Assessment',
      'statusDropdownLabel': 'Status',
      'results_page_heading_name': 'Results',
      'group_results_page_heading_name': 'Group Results',
      'bulkOrder_page_heading_name': 'Data Export History',
      'filters': 'Filters',
      'table_heading': 'List of Test Reports',
      'table_heading_group': 'List of Group Test Reports',
      'table_heading_export': 'List of Export Records ',
      'dropdown_text_status': 'Status',
      'search': 'Search',
      'reset': 'Reset',
      'close_label': 'Close',
      // Table Headers (Results Page)
      'table_header_no': 'No.',
      'table_header_name': 'Name',
      'table_header_employeeId': 'ID Number',
      'table_header_assessment_name': 'Assessment Name',
      'table_header_assessment_session': 'Session',
      'table_header_assessment': 'Assessment',
      'table_header_status': 'Status',
      'table_header_report': 'Report',
      'table_header_ai_report': 'Proctoring Report',
      'table_header_stakeholder_report': 'Stakeholder Report',
      'table_header_employee_report': 'Employee Report',
      'table_header_date': 'Date',
      'table_header_filter_applied': 'Filters',
      'table_header_download': 'Download',
      'search_placeholder': 'Search',
      'table_header_assessment_date': 'Assessment Date',
      'applied_filter_label': 'View Applied Filters',
      'applied_filter_card_title': 'Filters Applied',
      // Group Table Header
      'group_table_header_no': 'No.',
      'group_table_header_name': 'Report Name',
      'group_table_header_assessment': 'Assessment',
      'group_table_header_assessment_duration': 'Assessment Date',
      'group_table_header_report': 'Report',
      // Header
      'logout': 'Logout',
      // SideBar
      'sidebar_text_dashboard': 'Home',
      'sidebar_text_results': 'Results',
      'sidebar_text_export_data': 'Data Export History',
      'group_report': 'Group Report',
      'learning_material': 'Learning Resources',
      'sl_no': 'No.',
      'download_resources': 'Download Resources',
      'resource_name': 'Resource Name',
      'download': 'Download',
      'learning_material_page_heading_name': 'Learning Resources',
      'learning_table_heading_group': 'List of Learning Resources',
      // TimeOut Dialog
      'session_expire_heading': 'Session Expiration Warning',
      'session_expire_message_1': 'Your session will expire in',
      'session_expire_message_2': 'seconds. Please click “Continue” to continue your session or click “Log Out Now” to end your session now.',
      // Report Unavailable
      'report_unavailable': 'Please wait while the report is being generated.<br>If the problem persists please contact support',
      'export_unavailable': 'We encountered an error while exporting data , please try again.<br>If the problem persists please contact support',
      'export_assessment_for_export': 'Please select an assessment and click on search before exporting data for selected assessment',
      'export_button': 'Export',
      // Loader
      'loading': 'Loading',
      'text_all_sessions': 'All Sessions',
      // Dropdowns
      'dropdown_text_to': 'To',
      'dropdown_text_from': 'From',
      'text_all_dates': 'All Dates',
      'text_all_status': 'All Status',
      'text_all_assessments': 'All Assessments',
      'close_button': 'Close'
    }
}
