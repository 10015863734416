import { axiosBase } from '../_helpers'
import { rootUrl } from '../_config'

export default { getRequest, postRequest, fileDownloadPostRequest }

function getRequest (url) {
  return axiosBase.get(url)
    .catch(function (error) {
      if (error.response && error.response.status === 403) {
        if (window.$cookies.get('standard_dashboard_product') && window.$cookies.get('standard_dashboard_product') !== null && window.$cookies.get('standard_dashboard_product').trim() !== '') {
          window.location.href = rootUrl() + 'id/' + window.$cookies.get('standard_dashboard_product')
        } else {
          window.location.href = rootUrl()
        }
      } else {
        throw error
      }
    })
    .then(function (response) {
      return response.data
    })
}

function postRequest (url, data) {
  return axiosBase.post(url, data)
    .catch(function (error) {
      if (error.response && error.response.status === 403) {
        if (window.$cookies.get('standard_dashboard_product') && window.$cookies.get('standard_dashboard_product') !== null && window.$cookies.get('standard_dashboard_product').trim() !== '') {
          window.location.href = rootUrl() + 'id/' + window.$cookies.get('standard_dashboard_product')
        } else {
          window.location.href = rootUrl()
        }
      } else {
        throw error
      }
    })
    .then(function (response) {
      return response.data
    })
}

function fileDownloadPostRequest (url, data) {
  return axiosBase.post(url, data, { responseType: 'blob' })
    .catch(function (error) {
      if (error.response && error.response.status === 403) {
        if (window.$cookies.get('standard_dashboard_product') && window.$cookies.get('standard_dashboard_product') !== null && window.$cookies.get('standard_dashboard_product').trim() !== '') {
          window.location.href = rootUrl() + 'id/' + window.$cookies.get('standard_dashboard_product')
        } else {
          window.location.href = rootUrl()
        }
      } else {
        throw error
      }
    })
    .then(function (response) {
      return response.data
    })
}
