<template>
   <v-footer :absolute="false" class="footer" style="position: absolute;bottom: 0;width: 100%;">
     <div v-if="!overrideCopyRight" class="footer-font">© 2017- {{ new Date().getFullYear() }} Global Psytech Sdn. Bhd.</div>
     <div v-else-if="overrideCopyRight==='GFI'" class="footer-font">© 2017- {{ new Date().getFullYear() }} GFI Fintech.</div>
   </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      overrideCopyRight: ''
    }
  },
  created: function () {
    if (this.$cookies.get('standard_dashboard_copyright') && this.$cookies.get('standard_dashboard_copyright').trim() !== '') {
      this.overrideCopyRight = this.$cookies.get('standard_dashboard_copyright')
    }
  }
}
</script>

<style scoped>

</style>
