<template>
  <v-card class="my-2 mx-2">
    <v-card-text class="text--primary">
      <div class="tnc-max-height ">
        <div>
          <h2>PERSONAL DATA PROTECTION NOTICE FOR ASSESSMENT RATING SYSTEM</h2>
          <p>This Personal Data Notice (“Notice”) describes how Global Psytech Sdn Bhd (“the company”) who is the provider of Dashboard uses your Personal Data.</p>

          <p>The Dashboard is designed for tracking assessment completion status and download of reports.</p>

          <p>Please read this Notice carefully as our company wants to make a sincere and frank disclosure on the usage and processing of all Personal Data collected from clients.</p>

          <p>
            By using the Dashboard platform, you consent to the processing of your Personal Data by Global Psytech Sdn Bhd and all persons involved in the provision of services by Global Psytech Sdn Bhd.
          </p>
          <p>
            In the event of any conflict between the English and other language versions, the English version shall prevail.
          </p>

<!--          <div>-->
<!--            <ul style="list-style: decimal;">-->
<!--              <li>“Personal Data” means information about you which you have provided to the company, such as your name,-->
<!--                email address, and any information which may identify you that has been or may be collected, stored,-->
<!--                used and processed by the company from time to time by your usage of the Assessment Rating System-->
<!--                website.-->
<!--              </li>-->
<!--              <li>In addition, by using the Assessment Rating System platform and rating the assessments/exams therein,-->
<!--                you may have provided information from which the company may be able to predict your rating quality for-->
<!--                the purpose of deriving the subscribed analytical parameters. The company may use the data for future-->
<!--                analytical and/or commercial purposes.-->
<!--              </li>-->
<!--              <li>In addition to the Personal Data you provide to the company directly (i.e. during your engagement with-->
<!--                the Assessment Rating System website), the company may also collect your Personal Data from a variety of-->
<!--                sources, including without limitation at any events, conferences, seminars, web-conferences,-->
<!--                on-line/social media responses and/or talks organised or sponsored by the company and/or from the-->
<!--                cookies used on the Website.-->
<!--              </li>-->
<!--              <li>The provision of your Personal Data is voluntary. However, if you do not provide your Personal Data,-->
<!--                the company may not be able to provide you with the Assessment Rating System and access to the platform.-->
<!--              </li>-->
<!--              <li>-->
<!--                <h3>Other purposes</h3>-->
<!--                The company may also use and process your data for other purposes such as:-<br/>-->
<!--                <ul style="list-style: lower-alpha;">-->
<!--                  <li>a) to send you materials such as newsletters, articles, write-ups as well as other updates by way-->
<!--                    of email;-->
<!--                  </li>-->
<!--                  <li>b) to distribute information or details of events, seminars, conferences and talks which may of-->
<!--                    interest to you by way of email;-->
<!--                  </li>-->
<!--                  <li>c) to conduct internal surveys and data analytical activities whether for itself and/or third-->
<!--                    parties-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </li>-->
<!--              <li>-->
<!--                If you do not consent to the company processing your Personal Data for the purposes listed in Section 5,-->
<!--                please notify us by emailing us at support@globalpsytech.com. Kindly note that your refusal to accept-->
<!--                the terms herein may affect your access to Assessment Rating System platform. The company reserves its-->
<!--                right to control access to Assessment Rating System and impose terms and conditions as it thinks fit.-->
<!--                Transfer of Personal Data-->
<!--              </li>-->
<!--              <li>-->
<!--                The company’s information technology storage facilities and servers may be located in other-->
<!--                jurisdictions, your Personal Data may be transferred to, stored, used and processed in a jurisdiction-->
<!--                other than Malaysia. Further, your personal data may be transferred out of Malaysia for the purposes as-->
<!--                described in Section 5(c) where such potential clients and/or users are located outside of Malaysia. You-->
<!--                understand and consent to the transfer of your Personal Data out of Malaysia as described herein.-->
<!--                Disclosure to Third Parties-->
<!--              </li>-->
<!--              <li>-->
<!--                The company may engage other companies, service providers or individuals to perform functions on the-->
<!--                company’s behalf, and consequently may provide access or disclose to your Personal Data to the third-->
<!--                parties such as those listed below (not exhaustive):-->
<!--                <ul style="list-style: lower-alpha;">-->
<!--                  <li>-->
<!--                    information technology (IT) service providers;-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    data entry service providers;-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    storage facility providers;-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    banks and financial institutions including third party merchant payment providers;-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    insurance providers;-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    any professional advisors and external auditors;-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </li>-->
<!--              <li>-->
<!--                Subject to any exceptions under applicable laws, you may at any time hereafter request for access to, or-->
<!--                for correction or rectification of your Personal Data or limit the processing of your Personal Data, or-->
<!--                seek further information from the company by emailing us at support@globalpsytech.com.-->
<!--              </li>-->
<!--              <li>-->
<!--                Access & Correction Requests and Inquiries-->
<!--                In respect of your right to access and/or correct your Personal Data, the company has the right to-->
<!--                refuse your requests to access and/or make any correction to your Personal Data for the reasons-->
<!--                permitted under law, such as where the expense of providing access to you is disproportionate to the-->
<!--                risks to your privacy.-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
        </div>
        <div class="my-10">
          <h2>Terms & Conditions</h2>
          <p>The website https://www.dashboard.nedasmy.com/id/ptsbnm (hereinafter referred to as the “Site“) offers dashboard management service for stakeholders (hereinafter referred to as “Clients“) to track assessment completion status and download reports.

            The purpose of these Terms and Conditions of Services (hereinafter referred to as the “T&Cs”) is to define the terms and conditions relating to the provision of services on the Site (hereinafter referred to as “Services“).

            These T&Cs govern the contractual relationships between the provider and the Clients, who are subject to specific terms and conditions of services, which are available at https://www.dashboard.nedasmy.com/id/ptsbnm.

            The T&Cs are subject to the respect of the provisions of the Terms of Use, which are available at https://www.dashboard.nedasmy.com/id/ptsbnm.</p>
          <ul style="list-style: decimal;">
            <li>
              <h3>Acceptance and modification</h3>
              ACCEPTANCE<br/>
              Acceptance of these T&Cs shall be presented upon login.<br/>
              Any Client who does not accept to be bound by the provisions of the T&Cs will not be permitted access to the Site or use the Services.<br/>
              By clicking “Agree”, the Client commits to respect all of the provisions of the present T&Cs.<br/>
              MODIFICATION<br/>
              The Provider reserves the right to amend the T&Cs at any time and at its sole discretion. The Client will be informed of these amendments through any pertinent channel.<br/>
              Any Client who uses the Services after the entry into force of the amended T&Cs shall be deemed to have accepted these amendments.<br/><br/>
            </li>
            <li>
              <h3>Description of the Services</h3>
              The Provider offers dashboard management service to track assessment completion status and download reports.<br/>
              The Client will receive a username and a password that will give access to the system.<br/>
              The username and password will only be active within an allocated time, depending on business subscription model.<br/>
              The Provider reserves the right to propose any other Service that it deems useful, in a format and by the technical means that it deems most appropriate for providing the Service.<br/><br/>
            </li>
            <li>
              <h3>Financial conditions & Return Policy</h3>
              Prices of the product may be changed by the Provider at any time, at its sole discretion.<br/>
              Clients acknowledge that they are not entitled to refund of any part of their purchases.<br/>
              When technical difficulties prevent a Client from accessing the system within the time limits specified in Section 2, no refund will be provided, unless the technical difficulties occur within the system. The determination of the nature of any technical difficulties will be done by the system.<br/><br/>
            </li>
            <li>
              <h3>Modifications to the Service and Prices</h3>
              Prices for our products are subject to change without notice.<br/>
              We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.<br/>
              We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.<br/><br/>
            </li>
            <li>
              <h3>Clients’ obligations</h3>
              Clients commit to respect the provisions of the present T&Cs and the laws and regulations in force. They commit to respect any third party’s rights.<br/>
              Clients undertake not to transfer, assign or lease in any way all or part of their rights or obligations herein to any third party, or to transfer to any third party, the use of the system, or more generally, any information received from the Provider within the framework of the Services.<br/>
              Clients undertake to provide the Provider with all information necessary for the proper implementation of the Services.<br/>
              Each Client guarantees the Company against any claims, demands and/or actions that the Provider could incur as a result of a breach by the Client of any one of its obligations under these T&Cs.<br/><br/>
            </li>
            <li>
              <h3>Changes to Terms of Services</h3>
              You can review the most current version of the Terms of Service at any time at this page.<br/>
              We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.<br/><br/>
            </li>
            <li>
              <h3>Errors, Inaccuracies and Omissions</h3>
              Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to system operations and functions. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).<br/><br/>
            </li>
            <li>
              <h3>Limitation of liability</h3>
              The Provider will make its best effort to provide the Services with diligence and in compliance with trade practice.<br/>
              The System is meant for organisation use only. The Provider shall not be responsible for any misuse of its service whatsoever, including unauthorised use for assessment evaluation by any third party with or without the permission of the Client.<br/>
              The Provider will not under any circumstances accept liability towards the Client, for any reason whatsoever, for any indirect loss whatsoever, such as loss of revenue, profits, opportunity, clientele, or orders, damage to reputation, inaccuracy or corruption of files or loss of data, operating losses, business losses or any effect on the Client’s personal relationships as a result of the use of the platform and the information generated and shared as a result of the usage thereof.<br/><br/>
            </li>
            <li>
              <h3>Intellectual Properties & Copyright</h3>
              All rights reserved. No part of the system may be reproduced, distributed, or transmitted in any form or by any means, including screenshots, photocopying, recording, or other electronic or mechanical methods, without the prior written permission of the Provider.<br/><br/>
            </li>
            <li>
              <h3>Governing law – Competent jurisdiction</h3>
              The parties to this contract agree to submit to the jurisdiction of Malaysia. All contracts shall be interpreted according to Malaysian law.<br/><br/>
            </li>
          </ul>
        </div>
      </div>
    </v-card-text>

    <v-layout>
      <v-flex lg12 class="px-3 py-3">
        <v-layout>
          <v-checkbox v-model="dpaAgreed" :error="dpaError" v-on:change="errorCheck">
          </v-checkbox>
          <span class="mt-5" :class="{changeColor: dpaError}">I have read and understood the Personal Data Policy and agree to the use of my personal data as specified in that Policy. I have also read and agree to the Terms and Conditions.</span>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout>
        <v-flex class="px-10">
          <span class="changeColor" v-show="dpaError">Please provide your agreement by clicking on the check box</span>
        </v-flex>
    </v-layout>
      <v-layout class="mb-sm-10">
      <v-flex class="px-3 mt-3 d-flex justify-lg-start justify-center" style="margin-bottom: 40px"><v-btn class="primary" v-on:click="agreeDPA">
        Proceed
      </v-btn></v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { dashboardService } from '../_services'
import { rootUrl } from '../_config'

export default {
  name: 'TnC',
  data () {
    return {
      dpaAgreed: false,
      dpaError: false
    }
  },
  methods: {
    isLocalStorageAllowed: function () {
      return typeof (Storage) !== 'undefined'
    },
    agreeDPA: function () {
      this.dpaError = false
      if (this.dpaAgreed) {
        dashboardService.agreeDpa({ dpaStatus: this.dpaAgreed })
          .then(this.triggerRefresh())
          .catch(error => {
            this.loading = false
            this.overlay = false
            /* eslint-disable no-console */
            console.log('loggin response ' + error)
            /* eslint-enable no-console */
          })
      } else {
        this.dpaError = true
      }
    },
    errorCheck: function () {
      this.dpaError = false
      if (!this.dpaAgreed) {
        this.dpaError = true
      }
    },
    triggerRefresh: function () {
      window.location.href = rootUrl() + 'home'
    }
  }
}

</script>

<style>
  p {
    font-size: 15px;
  }

  .tnc-max-height {
    max-height: calc(100vh - 300px);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .changeColor {
    color: red;
  }
  .confirmation-box {
    width: auto;
    text-align: right;
  }

  .confirmation-checkbox {
    min-height: 30px;
  }
</style>
