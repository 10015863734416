<template>
  <div>
    <v-container>
      <v-layout align-center justify-center>
        <v-flex lg12 md12 sm12 xs12>
          <v-row justify-center>
            <v-col>
              <p class="text-center">You might have open the dashboard in another tab/window , please close this tab/window</p>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>

export default {
}

</script>

<style>
  p{
    font-size: 15px;
  }
</style>
