import { mdiFilter, mdiAccountGroupOutline, mdiMagnify, mdiDownload, mdiMinus, mdiAccount,
  mdiLock, mdiChartBar, mdiArchive, mdiChartPie, mdiCogs, mdiStopCircleOutline, mdiBellOutline,
  mdiFileDocument, mdiFolderOpen, mdiHome, mdiChevronRight, mdiAccountGroup, mdiWindowMinimize,
  mdiWindowClose, mdiCheckAll, mdiCheck, mdiAccountEditOutline, mdiMenu, mdiLogoutVariant, mdiFileDownloadOutline, mdiEyeOutline,
  mdiCancel, mdiArrowRightDropCircleOutline, mdiCalendar, mdiInformation, mdiClipboardCheckMultipleOutline, mdiBookMultiple } from '@mdi/js'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  icons: {
    iconfont: ['mdiSvg'],
    values: {
      mdiFilter: mdiFilter,
      mdiAccountGroupOutline: mdiAccountGroupOutline,
      mdiDownload: mdiDownload,
      mdiMinus: mdiMinus,
      mdiMagnify: mdiMagnify,
      mdiAccount: mdiAccount,
      mdiLock: mdiLock,
      mdiChartBar: mdiChartBar,
      mdiArchive: mdiArchive,
      mdiChartPie: mdiChartPie,
      mdiCogs: mdiCogs,
      mdiStopCircleOutline: mdiStopCircleOutline,
      mdiBellOutline: mdiBellOutline,
      mdiFileDocument: mdiFileDocument,
      mdiFolderOpen: mdiFolderOpen,
      mdiHome: mdiHome,
      mdiChevronRight: mdiChevronRight,
      mdiAccountGroup: mdiAccountGroup,
      mdiWindowMinimize: mdiWindowMinimize,
      mdiWindowClose: mdiWindowClose,
      mdiCheckAll: mdiCheckAll,
      mdiCheck: mdiCheck,
      mdiAccountEditOutline: mdiAccountEditOutline,
      mdiMenu: mdiMenu,
      mdiLogoutVariant: mdiLogoutVariant,
      mdiFileDownloadOutline: mdiFileDownloadOutline,
      mdiCancel: mdiCancel,
      mdiArrowRightDropCircleOutline: mdiArrowRightDropCircleOutline,
      mdiCalendar: mdiCalendar,
      mdiInformation: mdiInformation,
      mdiClipboardCheckMultipleOutline: mdiClipboardCheckMultipleOutline,
      mdiBookMultiple: mdiBookMultiple,
      mdiEyeOutline: mdiEyeOutline
    }
  }
})

export default vuetify
