<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="content">
    <div v-if="loadedBasicInfo">
      <div v-if="isDpaAccepted">
        <router-view></router-view>
      </div>
      <div v-else>
        <TnC/>
      </div>
    </div>
    <v-dialog v-model="idleTimeOver" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">{{$t('msg.session_expire_heading')}}</v-card-title>
        <v-card-text>{{$t('msg.session_expire_message_1')}} {{logOutTimeRemaining}} {{$t('msg.session_expire_message_2')}}</v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="green darken-1" text @click=triggerLogout>Log Out Now</v-btn>
          <v-btn color="green darken-1" text @click=triggerRefresh>Continue</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { homeService } from '../_services/index'
import { translationHelper } from '../_helpers'
import { rootUrl } from '../_config'
import TnC from '../components/TnC'

export default {
  name: 'home',
  components: {
    TnC
  },
  data () {
    return {
      idleTimeTicker: null,
      idleTimeRemaining: 900,
      idleTimerTickerStartedAt: 0,
      timerDurationPassed: 0,

      logOutTimeTicker: null,
      logOutTimeRemaining: 20,
      logOutTimerTickerStartedAt: 0,
      logOutTimerDurationPassed: 0,

      childComponentApiLoading: false,
      idleTimeOver: false,
      loadedBasicInfo: false
    }
  },
  methods: {
    isLocalStorageAllowed: function () {
      return typeof (Storage) !== 'undefined'
    },
    fetchInitializationData: function () {
      homeService.fetchHome()
        .then(response => {
          if (this.isLocalStorageAllowed()) {
            localStorage.setItem('_standard_dashboard_sid', response.dataResponse.state.sidIdentifier)
            this.$store.dispatch('home/setStdSidIdentifier', response.dataResponse.state.sidIdentifier)
          } else {
            /* eslint-disable no-console */
            console.log('Nedas Not supported')
            /* eslint-enable no-console */
          }
          let overrideColor = ''
          if (this.$cookies.get('standard_dashboard_override_post_login_color') && this.$cookies.get('standard_dashboard_override_post_login_color').trim() !== '') {
            overrideColor = this.$cookies.get('standard_dashboard_override_post_login_color')
          }

          this.$store.dispatch('home/setUserState', {
            authenticated: response.dataResponse.state.authenticated,
            fullName: response.dataResponse.state.fullName,
            language: response.dataResponse.state.language,
            role: response.dataResponse.state.role,
            organizationName: response.dataResponse.state.organizationName,
            showExportButton: response.dataResponse.state.showExportButton,
            groupReportsAvailable: response.dataResponse.state.groupReportsAvailable,
            learningMaterialAvailable: response.dataResponse.state.learningMaterialAvailable,
            employeeReportAvailable: response.dataResponse.state.employeeReportAvailable,
            username: response.dataResponse.state.username,
            dashboardLabel: response.dataResponse.state.dashboardLabel,
            dpaAccepted: response.dataResponse.state.dpaAccepted,
            showAiProctoringReports: response.dataResponse.state.showAiProctoringReports,
            externalVisualisationUrl: response.dataResponse.state.externalVisualisationUrl,
            overrideColor: overrideColor
          })
          this.loadedBasicInfo = true
        })
        .catch(function (error) {
          if (error.response && error.response.status === 403) {
            if (window.$cookies.get('standard_dashboard_product') && window.$cookies.get('standard_dashboard_product') !== null && window.$cookies.get('standard_dashboard_product').trim() !== '') {
              window.location.href = rootUrl() + 'id/' + this.$cookies.get('standard_dashboard_product')
            } else {
              window.location.href = rootUrl()
            }
          }
          /* eslint-disable no-console */
          console.log('loggin response ' + error)
          /* eslint-enable no-console */
        })
    },
    startIdleTicker: function () {
      this.idleTimeTicker = setInterval(() => {
        if (this.isUserAuthenticated && (this.idleTimeRemaining === 0 || this.idleTimeRemaining < 0)) {
          this.idleTimeOver = true
          this.startLogoutTicker()
          clearInterval(this.idleTimeTicker)
        } else {
          if (!this.childComponentApiLoading) {
            this.idleTimeRemaining = this.idleTimeRemaining - 1
            this.timerDurationPassed = this.timerDurationPassed + 1

            let displacement = (Math.floor((Date.now() - this.idleTimerTickerStartedAt) / 1000)) - this.timerDurationPassed
            if (displacement > 2) {
              this.idleTimeRemaining = this.idleTimeRemaining - displacement
              this.timerDurationPassed = this.timerDurationPassed + displacement
            }
          }
        }
      }, 1000)
      this.idleTimerTickerStartedAt = Date.now()
    },
    startLogoutTicker: function () {
      this.logOutTimeTicker = setInterval(() => {
        if (this.isUserAuthenticated && (this.logOutTimeRemaining === 1 || this.logOutTimeRemaining < 1)) {
          clearInterval(this.logOutTimeTicker)
          this.triggerLogout()
        } else {
          this.logOutTimeRemaining = this.logOutTimeRemaining - 1
          this.logOutTimerDurationPassed = this.logOutTimerDurationPassed + 1

          let displacement = (Math.floor((Date.now() - this.logOutTimerTickerStartedAt) / 1000)) - this.logOutTimerDurationPassed
          if (displacement > 2) {
            this.logOutTimeRemaining = this.logOutTimeRemaining - displacement
            this.logOutTimerDurationPassed = this.logOutTimerDurationPassed + displacement
          }
        }
      }, 1000)
      this.logOutTimerTickerStartedAt = Date.now()
    },
    resetIdleTimer: function () {
      this.idleTimerTickerStartedAt = Date.now()
      this.idleTimeRemaining = 900
    },
    triggerLogout: function () {
      clearInterval(this.logOutTimeTicker)
      window.location.href = rootUrl() + 'appLogout'
    },
    triggerRefresh: function () {
      window.location.href = rootUrl() + 'home'
    }
  },
  computed: {
    getUserRole () {
      return this.$store.state.home.user.role
    },
    isUserAuthenticated () {
      return this.$store.state.home.authenticated
    },
    isDpaAccepted () {
      return this.$store.state.home.user.dpaAccepted
    }
  },
  mounted () {
    history.pushState(null, null, document.URL)
    window.addEventListener('popstate', function () {
      history.pushState(null, null, document.URL)
    })
    window.history.pushState('homepage', 'Title', '/home')
    this.startIdleTicker()
    window.addEventListener('mouseup', this.resetIdleTimer)
    window.addEventListener('onmousedown', this.resetIdleTimer)
    window.addEventListener('ontouchstart', this.resetIdleTimer)
    window.addEventListener('onclick', this.resetIdleTimer)
    window.addEventListener('onscroll', this.resetIdleTimer)
    window.addEventListener('onkeypress', this.resetIdleTimer)

    if (this.$cookies.isKey('standard_dashboard_lang')) {
      translationHelper.loadLanguageAsync(this.$cookies.get('standard_dashboard_lang'))
    } else {
      this.$cookies.set('standard_dashboard_lang', 'en')
    }

    this.fetchInitializationData()
  },
  beforeDestroy () {
    clearInterval(this.idleTimeTicker)
  }
}
</script>
<style>
  .dashboard-datatable-footer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 5px 1px -2px rgba(0, 0, 0, 0.2), 0px 5px 2px 0px rgba(0, 0, 0, 0.14), 0px 5px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .dashboard-datatable-footer-actions {
    background: rgb(249, 249, 249);
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .content {
    min-height: calc(100vh - 50px);
  }
</style>
